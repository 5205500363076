export const updateRedirectUrlQueryParams = (
  url,
  appId,
  transactionId,
  status
) => {
  const urlObject = new URL(url);
  const finalStatus = status === "expired" ? "error" : status;
  if (urlObject.searchParams.size > 0) {
    return `${urlObject.toString()}&transactionId=${transactionId}&status=${finalStatus}`;
  }

  if (["ezs12s", "qw9rjg"].includes(appId)) {
    return `${url}?transactionId=${transactionId}&status=${finalStatus}`;
  }

  urlObject.searchParams.append("transactionId", transactionId);
  urlObject.searchParams.append("status", finalStatus);
  return urlObject.toString();
};
