import React from "react";
import PropTypes from "prop-types";
import {
  statusMsg,
  translateApplicationStatus,
} from "../utils/applicationStatus";
import "./components.scss";
import Animations from "./ResponseModal/Animations";
import Redirect from "./ResponseModal/Redirect";
import Footer from "./ResponseModal/Footer";

const ResponseModal = ({
  status,
  redirectURL,
  redirectTime,
  transactionId,
  appId,
  retryButtonVersion,
  footerVersion,
}) => {
  const darkMode = localStorage.getItem("darkMode") === "yes";
  const messages = statusMsg(retryButtonVersion);
  return (
    <div id="response_modal__container">
      <div id="response_modal__sub_container">
        <div style={{ margin: "auto" }}>
          <p
            id="response_modal__heading"
            className={darkMode ? "dark_mode_text" : ""}>
            {messages[translateApplicationStatus[status]].heading}
          </p>
          <p
            id="response_modal__subText"
            className={darkMode ? "dark_mode_text" : ""}>
            {messages[translateApplicationStatus[status]].subText}
          </p>
        </div>
        <Animations status={status} />
        {redirectURL ? (
          <Redirect
            url={redirectURL}
            transactionId={transactionId}
            status={status === "expired" ? "error" : status}
            appId={appId}
            redirectTime={redirectTime}
          />
        ) : (
          <p
            id="response_modal__redirect"
            className={darkMode ? "dark_mode_text" : ""}>
            {messages[translateApplicationStatus[status]].bottomText}
          </p>
        )}
        <Footer footerVersion={footerVersion} />
      </div>
    </div>
  );
};

export default ResponseModal;

ResponseModal.propTypes = {
  status: PropTypes.string.isRequired,
  redirectURL: PropTypes.string.isRequired,
  redirectTime: PropTypes.number.isRequired,
  transactionId: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired,
  retryButtonVersion: PropTypes.string.isRequired,
  footerVersion: PropTypes.string.isRequired,
};
