import Reload from "../components/ResponseModal/Reload";

export const statusMsg = (retryButtonVersion) => ({
  APPROVED: {
    heading: "Congratulations!",
    subText:
      "Thank you for verifying your identity. Your application has been approved.",
    bottomText: "Process completed. You can close the Tab.",
  },
  UNDERREVIEW: {
    heading: "Application is being processed",
    subText:
      "Your application is being reviewed. Once your identity is verified, you will be updated",
    bottomText: "You can close the Tab.",
  },
  REJECTED: {
    heading: "Application is being processed",
    subText:
      "Your application is being reviewed. Once your identity is verified, you will be updated",
    bottomText: "You can close the Tab.",
  },
  ERROR: {
    heading: "Unexpected error, sorry",
    subText:
      "Please retry after sometime. If the issue persists, you will receive a new link.",
    bottomText: "You can close the Tab.",
  },
  INVALID_STARTED: {
    heading: "Application submitted already",
    subText:
      "This link is already used. Please wait, you will receive a new link shortly.",
    bottomText: "You can close the Tab.",
  },
  INVALID_APPROVED: {
    heading: "Application submitted already",
    subText:
      "Your application has been approved. Thank you for verifying your identity.",
    bottomText: "Process completed. You can close the Tab.",
  },
  INVALID_REJECTED: {
    heading: "Application submitted already",
    subText:
      "Your application is being reviewed. Once your identity is verified, you will be updated",
    bottomText: "You can close the Tab.",
  },
  INVALID_UNDERREVIEW: {
    heading: "Application submitted already",
    subText:
      "Your application is being reviewed. Once your identity is verified, you will be updated",
    bottomText: "You can close the Tab.",
  },
  EXPIRED: {
    heading: "Link Expired",
    subText:
      "This link has expired. Please wait, you will receive a new link shortly.",
    bottomText: "You can close the Tab.",
  },
  CANCELLED: {
    heading: "Process Incomplete",
    subText:
      "The information you provided is insufficient to complete your KYC. Please reload and complete the process.",
    bottomText: <Reload version={retryButtonVersion} />,
  },
});

export const translateApplicationStatus = {
  auto_approved: "APPROVED",
  auto_declined: "REJECTED",
  needs_review: "UNDERREVIEW",
  user_cancelled: "CANCELLED",
  error: "ERROR",
  invalid_started: "INVALID_STARTED",
  invalid_auto_approved: "INVALID_APPROVED",
  invalid_auto_declined: "INVALID_REJECTED",
  invalid_needs_review: "INVALID_UNDERREVIEW",
  invalid_expired: "EXPIRED",
  expired: "EXPIRED",
};
