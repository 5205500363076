import React from "react";
import PropTypes from "prop-types";

const Reload = ({ version }) => {
  const reloadPage = () => {
    window.location.reload();
  };

  const buttonId = `retry_button_${version}`;

  return (
    <div className="hv-divforcover">
      <button id={buttonId} onClick={reloadPage}>
        Try again
      </button>
    </div>
  );
};

export default Reload;

Reload.propTypes = {
  version: PropTypes.string.isRequired,
};
