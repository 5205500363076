import React from "react";
import PropTypes from "prop-types";
import "./components.scss";

const Loader = ({ loaderColor, loaderVersion }) => {
  const loaderClass = `loader_${loaderVersion}`;
  const loaderBoxClass = `loader-box_${loaderVersion}`;

  return (
    <div
      className={loaderClass}
      style={{ borderBottomColor: `${loaderColor}` }}>
      <div className={loaderBoxClass}></div>
    </div>
  );
};

Loader.propTypes = {
  loaderColor: PropTypes.string.isRequired,
  loaderVersion: PropTypes.string.isRequired,
};

export default Loader;
