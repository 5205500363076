import LinkKyc from "./containers/LinkKyc";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <LinkKyc />
    </div>
  );
}

export default App;
